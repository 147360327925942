<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard color="primary" class="text-white">
          <CCardHeader>
            <strong>My Profile </strong><small>Edit Profil</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateProfilku">
            <CRow>
              <CCol sm="6">
              <CInput
                v-model="nama_lengkap"
                label="Name"
                placeholder="Input nama lengkap"
              />  
              </CCol> 
              <CCol sm="6">
              <CInput
                v-model="email"
                label="Email"
                readonly
                placeholder="Input email"
              />
              </CCol>     
            </CRow>            

                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/profilku">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Your profile failed updated.
    </CModal>

    <CModal color="success" title="Sukses" :show.sync="mySukses" size="sm">
      <b>Success</b>! Your profile succeed updated.
    </CModal>

    <CModal color="danger" title="Error" :show.sync="myError" size="sm">
      <b>Failed</b>! Your profile failed updated.
    </CModal>
   
  </div>
 
</template>

<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'
let user = JSON.parse(localStorage.getItem('user'));
export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      myError:false,
      mySukses:false,
      username : "",
      nama_lengkap : user.name,
      email : user.id,
      pass : "",
      level : "",
      selected: [], // Must be an array reference!
      // kegiatan: [],
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.showProfilku();
  },
  methods: {
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    updateProfilku: function(event){
      // Simple POST request with a JSON body using axios
      const profilku = { 
                          nama_lengkap: this.nama_lengkap, 
                          email: this.email
                          };
      // alert(JSON.stringify(user));
      axios.post(process.env.VUE_APP_BASE_URL+"updateprofilku", profilku)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.data=='sukses'){
              // user.name=this.nama_lengkap;
              this.showProfilku;
              this.mySukses = true;
            }
            else{
              this.myError = true;
            }
            
        })
    },
    showProfilku: function(event){
      // Simple POST request with a JSON body using axios
      const profilku = { 
                          nama_lengkap: this.nama_lengkap, 
                          email: this.email,
                          };
      // alert(JSON.stringify(user));
      // alert(this.$route.params.id_user);
      
      
      let username = window.btoa(user.id);

      axios.get(process.env.VUE_APP_BASE_URL+"profilku/"+username)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            // if(response.data=='sukses'){
              // this.$router.push('/kegiatan');
              this.nama_lengkap=response.data.nama_user;
              this.email=response.data.username;



              // alert(response.data);
            // }
            // else{
            //   this.$root.$emit("bv::show::modal", "modalError");
            // }
            
        })
    }
  }
}
</script>